import { axiosInstance } from "../../Axios";
import LocalStorage from "../../Data/LocalStorage";
import {
  GET_USER,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  SET_MUTED,
  USER_UPDATED,
  AVATAR_UPDATED
} from "../Constants/UserConstants";

export const getUser = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_USER,
      });

      const response = await axiosInstance.get("/profile", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      dispatch({
        type: GET_USER_SUCCESS,
        user: response.data,
      });
    } catch (error) {
      LocalStorage.remove("token");
      dispatch({ type: GET_USER_ERROR, error: error.response });
    }
  };
};


export const setMuted = () => {
  return (dispatch) => {
    dispatch({
      type: SET_MUTED,
    });
  }
}

export const updatedUser = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATED,
        user
      })
    } catch (error) {
      console.log('user updation failed')
    }
  }
}

export const updatedAvatar = (user) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: AVATAR_UPDATED,
        user
      })
    } catch (error) {
      console.log('Avatar updation failed')
    }
  }
}