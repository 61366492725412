import { ChevronUp } from 'lucide-react'
import React, { useState } from 'react'
import { MdBookmark, MdHomeFilled, MdOutlineLiveTv, MdOutlineVideoLibrary } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router'

function BottomNavBar() {

    const location = useLocation();
    const [activeTab, setActiveTab] = useState('home')
    const navigate = useNavigate()

    const handleNavigate = (tab, path) => {
        setActiveTab(tab)
        navigate(path);
    }

    return (
        <div className="md:invisible sm:visible fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-black bottom-0 left-1/2 dark:bg-gray-700 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
                <button
                    data-tooltip-target="tooltip-home"
                    type="button"
                    className={`inline-flex flex-col items-center justify-center px-5 group ${location?.pathname === '/' ? 'text-w2w-600' : 'text-white'
                        }`}
                    onClick={() => handleNavigate('home', '/')}
                >
                    <MdHomeFilled size={24} color={location?.pathname === '/' ? '#883cae' : 'white'} />
                    <span className="sr-only">Home</span>
                </button>
                <button
                    data-tooltip-target="tooltip-wallet"
                    type="button"
                    className={`inline-flex flex-col items-center justify-center px-5 group ${location?.pathname === '/watchlist' ? 'text-w2w-600' : 'text-white'
                        }`}
                    onClick={() => handleNavigate('watchlist', '/watchlist')}
                >
                    <MdBookmark size={24} color={location?.pathname === '/watchlist' ? '#883cae' : 'white'} />
                    <span className="sr-only">Watchlist</span>
                </button>
                <div className="flex items-center justify-center">
                    <button
                        data-tooltip-target="tooltip-new"
                        type="button"
                        className={`mb-5 inline-flex items-center justify-center w-10 h-10 font-medium bg-gray-600 rounded-full ${location?.pathname === '/account' ? 'custom-w2w' : 'text-white'
                            }`}
                        onClick={() => handleNavigate('account', '/account')}
                    >
                        <ChevronUp size={24} color='white' />
                        <span className="sr-only">Profile</span>
                    </button>
                </div>
                <button
                    data-tooltip-target="tooltip-settings"
                    type="button"
                    className={`inline-flex flex-col items-center justify-center px-5 group ${location?.pathname === '/movies' ? 'text-w2w-600' : 'text-white'
                        }`}
                    onClick={() => handleNavigate('movies', '/movies')}
                >
                    <MdOutlineVideoLibrary size={24} color={location?.pathname === '/movies' ? '#883cae' : 'white'} />
                    <span className="sr-only">Movies</span>
                </button>
                <button
                    data-tooltip-target="tooltip-profile"
                    type="button"
                    className={`inline-flex flex-col items-center justify-center px-5 group ${location?.pathname === '/tvshows' ? 'text-w2w-600' : 'text-white'
                        }`}
                    onClick={() => handleNavigate('tvshows', '/tvshows')}
                >
                    <MdOutlineLiveTv size={24} color={location?.pathname === '/tvshows' ? '#883cae' : 'white'} />
                    <span className="sr-only">Tv Shows</span>
                </button>
            </div>
        </div>
    )
}

export default BottomNavBar