import {
  GET_USER,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  REMOVE_USER,
  SET_MUTED,
  USER_UPDATED,
  AVATAR_UPDATED,
} from "../Constants/UserConstants";

const initialState = {
  user: null,
  loading: false,
  error: null,
  muted: true
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      return { ...state, loading: true };

    case GET_USER_SUCCESS:
      return { ...state, loading: false, user: action.user, error: null };

    case GET_USER_ERROR:
      return { ...state, loading: false, user: null, error: action.error };

    case REMOVE_USER:
      return { ...state, loading: false, user: null, error: null };

    case SET_MUTED:
      return {...state, muted: !state.muted}

    case USER_UPDATED:
      return {...state, user: action.user}

    case AVATAR_UPDATED:
      return{...state, user: action.user}

    default:
      return state;
  }
};

export default userReducer;
