import {
  GET_HOME_LIST,
  GET_HOME_LIST_ERROR,
  GET_HOME_LIST_SUCCESS,
  GET_HOME_SLIDER,
  GET_HOME_SLIDER_ERROR,
  GET_HOME_SLIDER_SUCCESS,
  GET_MOVIE_LIST,
  GET_MOVIE_LIST_ERROR,
  GET_MOVIE_LIST_SUCCESS,
  GET_TV_SHOW_LIST,
  GET_TV_SHOW_LIST_SUCCESS,
  GET_TV_SHOW_LIST_ERROR,
  GET_SINGLE_SHOW_LIST,
  GET_SINGLE_SHOW_LIST_SUCCESS,
  GET_SINGLE_SHOW_LIST_ERROR,

  GET_MORE_LIKE_THIS_LIST,
  GET_MORE_LIKE_THIS_LIST_SUCCESS,
  GET_MORE_LIKE_THIS_LIST_ERROR,
  GET_SUBSCRIPTION_LIST,
  GET_SUBSCRIPTION_LIST_SUCCESS,
  GET_SUBSCRIPTION_LIST_ERROR,
  GET_PACKAGE_LIST,
  GET_PACKAGE_LIST_SUCCESS,
  GET_PACKAGE_LIST_ERROR,
  GET_SEARCH_RESULTS,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_ERROR,
  GET_WATCH_LIST,
  GET_WATCH_LIST_SUCCESS,
  GET_WATCH_LIST_ERROR,
  GET_WATCH_LIST_ID,
  GET_WATCH_LIST_SUCCESS_ID,
  GET_WATCH_LIST_ERROR_ID,
  ADD_TO_WATCH_LIST,
  ADD_TO_WATCH_LIST_SUCCESS,
  ADD_TO_WATCH_LIST_ERROR,
  SAVE_CONTINUE_WATCHING,
  SAVE_CONTINUE_WATCHING_SUCCESS,
  SAVE_CONTINUE_WATCHING_ERROR,
  FETCH_CONTINUE_WATCHING,
  FETCH_CONTINUE_WATCHING_SUCCESS,
  FETCH_CONTINUE_WATCHING_ERROR,
  SAVE_DEVICE_DETAILS,
  SAVE_DEVICE_DETAILS_SUCESS,
  SAVE_DEVICE_DETAILS_ERROR,
  PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR,
  PROFILE_AVATAR_UPDATE,
  PROFILE_AVATAR_UPDATE_SUCCESS,
  PROFILE_AVATAR_UPDATE_ERROR,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  GET_PLATFORM_LIST,
  GET_PLATFORM_LIST_SUCCESS,
  GET_PLATFORM_LIST_ERROR,
  SET_TOAST_MESSAGE,

} from "../Constants/DataConstants";

const initialState = {
  slider: [],
  listing: [],
  movielisting: [],
  tvShowListing: [],
  packageListing: [],
  singleTvShowListing: [],
  currentTvShow: null,
  currentWatch: null,
  moreLikeThisListing: [],
  subscriptionListing: [],
  searchResult: [],
  watchListResult: [],
  watchListResult: [],
  watchListById: [],
  addContinueWatching: [],
  continueWatching: [],
  deviceDetails: [],
  UpdateProfile: [],
  updateAvatar: [],
  UpdatePassword: [],
  getplatformList: [],
  loading: {
    slider: false,
    listing: false,
    movielisting: false,
    tvShowListing: false,
    packageListing: false,
    singleTvShowListing: false,
    moreLikeThisListing: false,
    subscriptionListing: false,
    searchResult: false,
    watchListResult: false,
    watchListById: false,
    addToWatchList: false,
    saveContinueWatching: false,
    continueWatching: false,
    deviceDetails: false,
    UpdateProfile: false,
    updateAvatar: false,
    UpdatePassword: false,
    getplatformList: false,
  },
  error: {
    slider: null,
    listing: null,
    movielisting: null,
    tvShowListing: null,
    packageListing: null,
    singleTvShowListing: null,
    moreLikeThisListing: null,
    subscriptionListingnull: null,
    searchResult: null,
    watchListResult: null,
    watchListById: null,
    addToWatchList: null,
    saveContinueWatching: null,
    continueWatching: null,
    deviceDetails: null,
    UpdateProfile: null,
    updateAvatar: null,
    UpdatePassword: null,
    getplatformList: null,
  },

  toast: {
    message: null,
    type: null,
  }
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_SLIDER:
      return { ...state, loading: { ...state.loading, slider: true } };
    case GET_HOME_SLIDER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, slider: false },
        slider: action.slider,
      };
    case GET_HOME_SLIDER_ERROR:
      return {
        ...state,
        loading: { ...state.loading, slider: true },
        error: { ...state.error, slider: action.error },
      };

    // Home Listing
    case GET_HOME_LIST:
      return { ...state, loading: { ...state.loading, listing: true } };
    case GET_HOME_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, listing: false },
        listing: action.listing,
      };
    case GET_HOME_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, listing: false },
        error: { ...state.error, listing: action.error },
      };

    // Movie Listing
    case GET_MOVIE_LIST:
      return { ...state, loading: { ...state.loading, movielisting: true } };
    case GET_MOVIE_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, movielisting: false },
        movielisting: action.movielisting,
      };
    case GET_MOVIE_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, movielisting: false },
        error: { ...state.error, movielisting: action.error },
      }

    // TV Show
    case GET_TV_SHOW_LIST:
      return { ...state, loading: { ...state.loading, tvShowListing: true } }
    case GET_TV_SHOW_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, tvShowListing: false },
        tvShowListing: action.tvShowListing,
      };
    case GET_TV_SHOW_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, tvShowListing: false },
        error: { ...state.error, tvShowListing: action.error },
      }

    // Packages
    case GET_PACKAGE_LIST:
      return { ...state, loading: { ...state.loading, packageListing: true } };
    case GET_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, packageListing: false },
        packageListing: action.packageListing,
      };
    case GET_PACKAGE_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, packageListing: false },
        error: { ...state.error, packageListing: action.error },
      }

    // Single Tv Show
    case GET_SINGLE_SHOW_LIST:
      return { ...state, loading: { ...state.loading, singleTvShowListing: true } };
    case GET_SINGLE_SHOW_LIST_SUCCESS:
      if (action.singleTvShowListing?.type === 'series') {
        return {
          ...state,
          loading: { ...state.loading, singleTvShowListing: false },
          singleTvShowListing: action.singleTvShowListing,
          currentTvShow: action.singleTvShowListing,
        }
      }
      return {
        ...state,
        loading: { ...state.loading, singleTvShowListing: false },
        singleTvShowListing: action.singleTvShowListing,
        currentWatch: action.singleTvShowListing,
      };

    case GET_SINGLE_SHOW_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, singleTvShowListing: false },
        error: { ...state.error, singleTvShowListing: action.error },
      };


    // More Like This
    case GET_MORE_LIKE_THIS_LIST:
      return { ...state, loading: { ...state.loading, moreLikeThisListing: true } };
    case GET_MORE_LIKE_THIS_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, moreLikeThisListing: false },
        moreLikeThisListing: action.moreLikeThisListing,
      };
    case GET_MORE_LIKE_THIS_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, moreLikeThisListing: false },
        error: { ...state.error, moreLikeThisListing: action.error },
      }
    // Subscription
    case GET_SUBSCRIPTION_LIST:
      return { ...state, loading: { ...state.loading, subscriptionListing: true } };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, subscriptionListing: false },
        subscriptionListing: action.subscriptionListing,
      };
    case GET_SUBSCRIPTION_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, subscriptionListing: false },
        error: { ...state.error, subscriptionListing: action.error },
      }

    // Search
    case GET_SEARCH_RESULTS:
      return { ...state, loading: { ...state.loading, searchResult: true } };
    case GET_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, searchResult: false },
        searchResult: action.searchResult,
      };
    case GET_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        loading: { ...state.loading, searchResult: false },
        error: { ...state.error, searchResult: action.error },
      }

    // watchList
    case GET_WATCH_LIST:
      return { ...state, loading: { ...state.loading, watchListResult: true } };
    case GET_WATCH_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, watchListResult: false },
        watchListResult: action.watchListResult,
      };
    case GET_WATCH_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, watchListResult: false },
        error: { ...state.error, watchListResult: action.error },
      }

    // Watchlist By Id
    case GET_WATCH_LIST_ID:
      return { ...state, loading: { ...state.loading, watchListById: true } };
    case GET_WATCH_LIST_SUCCESS_ID:
      return {
        ...state,
        loading: { ...state.loading, watchListById: false },
        watchListById: action.watchListById,
      };
    case GET_WATCH_LIST_ERROR_ID:
      return {
        ...state,
        loading: { ...state.loading, watchListById: false },
        error: { ...state.error, watchListById: action.error },
      }

    // Add to Watchlist
    case ADD_TO_WATCH_LIST:
      return {
        ...state,
        loading: { ...state.loading, addToWatchList: true },
      };

    case ADD_TO_WATCH_LIST_SUCCESS:

      if (action.action === 'remove') {
        return {
          ...state,
          loading: { ...state.loading, addToWatchList: false },
          watchListResult: state.watchListResult.filter(video => video.id !== action.videoId),
        }
      }

      return {
        ...state,
        loading: { ...state.loading, addToWatchList: false },
        watchListResult: [...state.watchListResult, action.addedVideo],
      };

    case ADD_TO_WATCH_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, addToWatchList: false },
        error: { ...state.error, addToWatchList: action.error },
      };

    // Continue Watching
    case SAVE_CONTINUE_WATCHING:
      return {
        ...state,
        loading: { ...state.loading, saveContinueWatching: true },
      };

    case SAVE_CONTINUE_WATCHING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, saveContinueWatching: false },
        addContinueWatching: [...state.addContinueWatching, action.addedVideo],
      };

    case SAVE_CONTINUE_WATCHING_ERROR:
      return {
        ...state,
        loading: { ...state.loading, saveContinueWatching: false },
        error: { ...state.error, saveContinueWatching: action.error },
      };

    // Get continue watching list
    case FETCH_CONTINUE_WATCHING:
      return { ...state, loading: { ...state.loading, continueWatching: true } };
    case FETCH_CONTINUE_WATCHING_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, continueWatching: false },
        continueWatching: action.continueWatching,
      };
    case FETCH_CONTINUE_WATCHING_ERROR:
      return {
        ...state,
        loading: { ...state.loading, continueWatching: false },
        error: { ...state.error, continueWatching: action.error },
      }

    // Save Device Details
    case SAVE_DEVICE_DETAILS:
      return { ...state, loading: { ...state.loading, deviceDetails: true } };
    case SAVE_DEVICE_DETAILS_SUCESS:
      return {
        ...state,
        loading: { ...state.loading, deviceDetails: false },
        deviceDetails: action.deviceDetails,
      };
    case SAVE_DEVICE_DETAILS_ERROR:
      return {
        ...state,
        loading: { ...state.loading, deviceDetails: false },
        error: { ...state.error, deviceDetails: action.error },
      }

    // Update profile details
    case PROFILE_UPDATE:
      return { ...state, loading: { ...state.loading, UpdateProfile: true } };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, UpdateProfile: false },
        UpdateProfile: action.UpdateProfile,
      };
    case PROFILE_UPDATE_ERROR:
      return {
        ...state,
        loading: { ...state.loading, UpdateProfile: false },
        error: { ...state.error, UpdateProfile: action.error },
      };

    // Update Profile Avatar
    case PROFILE_AVATAR_UPDATE:
      return { ...state, loading: { ...state.loading, updateAvatar: true } }
    case PROFILE_AVATAR_UPDATE_SUCCESS:

      return {
        ...state,
        loading: { ...state.loading, updateAvatar: false },
        updateAvatar: action.updateAvatar,
        success: { ...state.success, updateAvatar: "Profile avatar image successfully." },
      };
    case PROFILE_AVATAR_UPDATE_ERROR:
      return {
        ...state,
        loading: { ...state.loading, updateAvatar: false },
        error: { ...state.error, updateAvatar: action.error },
      };
    //Update Passowrd
    case UPDATE_PASSWORD:
      return { ...state, loading: { ...state.loading, UpdatePassword: true } }
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, UpdatePassword: false },
        UpdatePassword: action.UpdatePassword,
        success: { ...state.success, UpdatePassword: "Password changed successfully" },
      };
    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: { ...state.loading, UpdatePassword: false },
        error: { ...state.error, UpdatePassword: action.error },
      };

    // Get platform List
    case GET_PLATFORM_LIST:
      return { ...state, loading: { ...state.loading, getplatformList: true } }
    case GET_PLATFORM_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getplatformList: false },
        getplatformList: action.getplatformList,
      }
    case GET_PLATFORM_LIST_ERROR:
      return {
        ...state,
        loading: { ...state.loading, getplatformList: false },
        error: { ...state.error, getplatformList: action.error },
      }

    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toast: {
          message: action.message,
          type: action.toastType,
        },
      };
    default:
      return state;
  }
};

export default dataReducer;
