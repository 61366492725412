import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { getUser } from "./Redux/Actions/UserActions";
import Loader from "./Component/Loader";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { logEvent } from "firebase/analytics";
import BottomNavBar from "./Component/BottomNavBar";

const Home = React.lazy(() => import("./Pages/Home"));
const Packages = React.lazy(() => import("./Pages/Packages"));
const Checkout = React.lazy(() => import("./Pages/Checkout"));
const ForgotPassword = React.lazy(() => import("./Pages/Auth/ForgotPasswordPage"));
const Account = React.lazy(() => import("./Pages/Account"));
const Description = React.lazy(() => import("./Pages/Descriptions"));
const Watch = React.lazy(() => import("./Pages/PlayingVideo"));
const TvShows = React.lazy(() => import("./Pages/TvShows"));
const Season = React.lazy(() => import("./Pages/Season"));
const Movies = React.lazy(() => import("./Pages/Movie"));
const CategoryPage = React.lazy(() => import("./Pages/CategorySingle"));
const WatchList = React.lazy(() => import("./Pages/WatchLists"));
const DeleteAccount = React.lazy(() => import("./Pages/DeleteAccount"));
const PaymentHistory = React.lazy(() => import("./Component/PaymentHistory"));
const Player = React.lazy(() => import("./Component/Player"));
const EmailVerify = React.lazy(() => import("./Pages/EmailVerify"));
const PasswordChange = React.lazy(() => import("./Component/PasswordChange"));
const LoginPage = React.lazy(() => import("./Pages/Auth/LoginPage"));
const SignupPage = React.lazy(() => import("./Pages/Auth/SignupPage"));
const ForgotPasswordPage = React.lazy(() => import("./Pages/Auth/ForgotPasswordPages"));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyCJzMvV2se4FCBE2rYeGgaTp4tSPSOXigs",
      authDomain: "women-to-women-tv.firebaseapp.com",
      projectId: "women-to-women-tv",
      storageBucket: "women-to-women-tv.firebasestorage.app",
      messagingSenderId: "460118741413",
      appId: "1:460118741413:web:ed7c2f7ea80a4e507a184c",
      measurementId: "G-4ZX3GL2WRQ",
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    logEvent(analytics, "page_view", { page_title: "Main App" });
    logEvent(analytics, "button_click", { button_name: "Get Started" });
  }, []);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <div className="App">

      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loader />}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loader />}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path="/reset-password"
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgotPassword />
            </React.Suspense>
          }
        />
        <Route
          path="/account"
          element={
            <React.Suspense fallback={<Loader />}>
              <Account />
            </React.Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <React.Suspense fallback={<Loader />}>
              <LoginPage />
            </React.Suspense>
          }
        />
        <Route
          path="/signup"
          element={
            <React.Suspense fallback={<Loader/>}>
              <SignupPage />
            </React.Suspense>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <React.Suspense fallback={<Loader/>}>
              <ForgotPasswordPage />
            </React.Suspense>
          }
        />
        <Route
          path="/subscription/plans"
          element={
            <React.Suspense fallback={<Loader />}>
              <Packages />
            </React.Suspense>
          }
        />
        <Route
          path="/checkout"
          element={
            <React.Suspense fallback={<Loader />}>
              <Checkout />
            </React.Suspense>
          }
        />
        <Route
          path="/description/:videoId"
          element={
            <React.Suspense fallback={<Loader />}>
              <Description />
            </React.Suspense>
          }
        />
        <Route
          path="/watch/:videoId"
          element={
            <React.Suspense fallback={<Loader />}>
              <Watch />
            </React.Suspense>
          }
        />
        <Route
          path="/tvshows"
          element={
            <React.Suspense fallback={<Loader />}>
              <TvShows />
            </React.Suspense>
          }
        />
        <Route
          path="/season/:videoId"
          element={
            <React.Suspense fallback={<Loader />}>
              <Season />
            </React.Suspense>
          }
        />
        <Route
          path="/movies"
          element={
            <React.Suspense fallback={<Loader />}>
              <Movies />
            </React.Suspense>
          }
        />
        <Route
          path="/category/:categoryId"
          element={
            <React.Suspense fallback={<Loader />}>
              <CategoryPage />
            </React.Suspense>
          }
        />
        <Route
          path="/watchlist"
          element={
            <React.Suspense fallback={<Loader />}>
              <WatchList />
            </React.Suspense>
          }
        />
        <Route
          path="/account/delete"
          element={
            <React.Suspense fallback={<Loader />}>
              <DeleteAccount />
            </React.Suspense>
          }
        />
        <Route
          path="/account/payment/history"
          element={
            <React.Suspense fallback={<Loader />}>
              <PaymentHistory />
            </React.Suspense>
          }
        />
        <Route
          path="/player"
          element={
            <React.Suspense fallback={<Loader />}>
              <Player />
            </React.Suspense>
          }
        />

        <Route
          path="/verify-email/:id/:hash"
          element={
            <React.Suspense fallback={<Loader />}>
              <EmailVerify />
            </React.Suspense>
          }
        />
        <Route
          path="/password/change"
          element={
            <React.Suspense fallback={<Loader />}>
              <PasswordChange />
            </React.Suspense>
          }
        />
      </Routes>
          <BottomNavBar/>
    </div>
  );
}

export default App;
